import React from "react";
import { graphql } from "gatsby";
import { Layout } from "../components/Layout";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { VehicleOverview } from "../components/VehicleOverview";

export const Vehicle: React.FC = ({ data }) => (
  <Layout>
    {data.allWordpressWpVehicle.edges.map(({ node }) => (
      <div key={node.id}>
        <Breadcrumbs title={node.title} />
        <VehicleOverview key={node.id} data={node} />
      </div>
    ))}
  </Layout>
);

export const pageQuery = graphql`
  query Vehicle($id: String) {
    allWordpressWpVehicle(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          title
          slug
          acf {
            gallery {
              source_url
              alt_text
            }
            year
            manufacturer
            model
            mileage
            price
            engine
            transmission
            fuel
            paint
            type
            doors
            seats
            description
            video
            other
          }
        }
      }
    }
  }
`;

export default Vehicle;
