import React from "react";
import { Link } from "gatsby";
import {
  VehicleOverview as StyledVehicleOverview,
  VehicleGallery,
  GalleryWrapper,
  VehicleDetails,
  Heading,
  Description,
  Price,
  CTA,
  Button,
  DetailsList,
  Specs,
  Spec,
  Label,
  Value,
  Content,
  Text,
  Video,
} from "./styles";
import { Carousel } from "../Carousel";
import { SectionHeading } from "../SectionHeading";
import { formatMileage, formatPrice } from "../../utils";
import placeholder from "../../assets/placeholder.jpg";

interface VehicleOverviewType {
  data: {
    title: string;
    acf: {
      gallery: [{ source_url: string; alt_text: string }];
      transmission: string;
      paint: string;
      engine: string;
      doors?: number;
      seats?: number;
      price: number;
      mileage: number;
      fuel: string;
      type: string;
      description: string;
      video: string;
      other: string;
    };
  };
}

export const VehicleOverview: React.FC<VehicleOverviewType> = ({
  data: { title, acf },
}) => {
  const hasImage = acf.gallery && acf.gallery.length;
  return (
    <StyledVehicleOverview>
      <VehicleGallery hasImage={hasImage}>
        <GalleryWrapper>
          {hasImage ? (
            <Carousel>
              {acf.gallery &&
                acf.gallery.map((image) => (
                  <div key={image.alt_text}>
                    <img src={`${image.source_url}`} alt={image.alt_text} />
                  </div>
                ))}
            </Carousel>
          ) : (
            <img src={placeholder} />
          )}
        </GalleryWrapper>
      </VehicleGallery>
      <VehicleDetails>
        <Heading>{title}</Heading>
        {
          <Description>{`${acf.transmission} | ${acf.engine} | ${
            acf.paint
          } | ${formatMileage(acf.mileage)}`}</Description>
        }
        <Price>{acf.price > 0 ? formatPrice(acf.price) : "N/A"}</Price>

        <CTA>
          <Link to="/#contact-us">
            <Button>Get in touch</Button>
          </Link>
        </CTA>

        <DetailsList>
          <Specs>
            {acf.engine && (
              <Spec>
                <Value>{acf.engine}</Value>
                <Label>Engine</Label>
              </Spec>
            )}
            {acf.fuel && (
              <Spec>
                <Value>{acf.fuel}</Value>
                <Label>Fuel</Label>
              </Spec>
            )}
            {acf.transmission && (
              <Spec>
                <Value>{acf.transmission}</Value>
                <Label>Transmission</Label>
              </Spec>
            )}
            {acf.paint && (
              <Spec>
                <Value>{acf.paint}</Value>
                <Label>Colour</Label>
              </Spec>
            )}
            {acf.mileage && (
              <Spec>
                <Value>{formatMileage(acf.mileage)}</Value>
                <Label>Miles</Label>
              </Spec>
            )}
            {acf.type && (
              <Spec>
                <Value>{acf.type}</Value>
                <Label>Type</Label>
              </Spec>
            )}
            {acf.doors && (
              <Spec>
                <Value>{acf.doors}</Value>
                <Label>Doors</Label>
              </Spec>
            )}
            {acf.seats && (
              <Spec>
                <Value>{acf.seats}</Value>
                <Label>Seats</Label>
              </Spec>
            )}
          </Specs>
        </DetailsList>
      </VehicleDetails>
      <Content>
        {acf.description && (
          <>
            <SectionHeading>Overview</SectionHeading>
            <Text dangerouslySetInnerHTML={{ __html: acf.description }} />
          </>
        )}
        {acf.video && (
          <>
            <SectionHeading>Hands on tour</SectionHeading>
            <Video dangerouslySetInnerHTML={{ __html: acf.video }} />
          </>
        )}
        {acf.other && <Text dangerouslySetInnerHTML={{ __html: acf.other }} />}
      </Content>
    </StyledVehicleOverview>
  );
};
