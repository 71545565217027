import styled from "styled-components";
import { Button as _Button } from "../Button";
import { Card as _Card } from "../Card";
import { Link as _Link } from "../Link";
import { lightGrey, black } from "../../styles/colors";

export const Link = styled(_Link)``;

export const VehicleOverview = styled.div``;

export const VehicleGallery = styled.div`
  /* @media (min-width: 850px) { */
  background: ${({ hasImage }) => (hasImage ? "#0b0b0b" : `${lightGrey}`)};
  /* } */

  img {
    max-width: 100%;
    margin: 0 auto;
  }
`;

export const GalleryWrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;
`;

export const VehicleDetails = styled(_Card)`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  margin: -5px auto 40px;
  padding: 30px 20px 20px;
  max-width: 1000px;
  border-radius: 0;

  @media (min-width: 850px) {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-column-gap: 40px;
    margin-top: -40px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 5px;
  }
`;

export const Details = styled.div`
  @media (min-width: 850px) {
    grid-column-start: 1;
    grid-column-end: 2;
  }
`;

export const Price = styled.span`
  position: relative;
  order: 1;
  margin-bottom: 0;
  padding-top: 18px;
  font-size: 42px;
  font-weight: 500;
  letter-spacing: 0.5px;

  &::before {
    content: "Our price";
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    font-weight: 300;
  }
`;

export const Heading = styled.h1`
  order: 2;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 1px;
  margin-top: 15px;
  margin-bottom: 5px;
  line-height: 1.1;

  @media (min-width: 850px) {
    grid-column: 1 / 2;
  }
`;

export const Description = styled.p`
  order: 3;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.75px;
  line-height: 1.3;
  margin-bottom: 30px;

  @media (min-width: 850px) {
    grid-column: 1 / 2;
  }
`;

export const CTA = styled.div`
  order: 4;

  @media (min-width: 850px) {
    grid-column: 2 / 3;
    grid-row: 2 / 4;
  }
`;

export const Button = styled(_Button)`
  width: 100%;
`;

export const DetailsList = styled.div`
  order: 5;

  @media (min-width: 850px) {
    grid-column: 1 / 3;
  }
`;

export const DetailsItem = styled.li`
  /* margin-bottom: 12px;
  font-weight: 300;
  color: ${black};
  margin-left: 20px; */
`;

export const Specs = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 30px 10px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 30px;
  margin-bottom: 20px;
  padding-top: 30px;
  border-top: 1px solid ${lightGrey};

  @media (min-width: 575px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 850px) {
    margin-top: 0;
    grid-template-columns: repeat(8, auto);
  }
`;

export const Spec = styled.div`
  text-align: center;
`;

export const Value = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 15px;
  font-weight: 500;
  text-transform: capitalize;

  @media (min-width: 850px) {
    height: 30px;
  }
`;

export const Label = styled.h3`
  margin-top: 10px;
  font-size: 13px;
`;

export const Content = styled.div`
  max-width: 960px;
  margin: 50px auto;
  padding-left: 10px;
  padding-right: 10px;
`;

export const Text = styled.div`
  margin-bottom: 50px;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.5;

  p {
    margin-bottom: 20px;
  }
`;

export const Video = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  margin-bottom: 30px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;
