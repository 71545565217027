import React from "react";
import Slider from "react-slick";

export const Carousel = ({ children }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    adaptiveHeight: true,
  };

  return <Slider {...settings}>{children}</Slider>;
};
